<template>
  <div class="unpined-chat-wrapper">
    <chat-window
      v-if="init"
      :conversationId="conversationId"
      :currentThreadId="threadId"
      unpined
    />
    <Loader
      v-else
    />
  </div>
</template>

<script>
import ChatWindow from '@/chat/Window/ChatWindow'
import Loader from '@/components/Loader'
export default {
  components: {
    ChatWindow,
    Loader
  },
  data () {
    return {
      init: false
    }
  },
  computed: {
    initUnPinedChat () {
      return this.$store.getters['users/users'] && this.$store.getters['users/users'].length > 0
    },
    conversationId () {
      return this.$route.params.id
    },
    threadId () {
      return this.$route.params.threadId || null
    },
    autostart () {
      return this.$store.getters['chat/autostart']
    }
  },
  watch: {
    async initUnPinedChat (newValue) {
      if (!newValue) {
        return
      }

      this.init = true
    }
  },
  async created () {
    this.$store.commit('chat/SET_UNPINED_WINDOW')

    if (!this.currentWindow) {
      if (!window.opener) self.close()
    }

    if (window.history.length > 1) {
      this.init = true
    }
  },
  methods: {}
}
</script>

<style scoped>
.unpined-chat-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: white;
}
</style>
